/*
 ***********************************************************************************************************************************
 *
 * Load all js dependencies
 *
 * Eseguire npm update
 *
 * Eseguire yarn add modulo per aggiungere nuovi moduli
 * Eseguire npm run dev ogni volta che si aggiorna questo file IN DEV
 * Eseguire npm run prod ogni volta che si aggiorna questo file IN PROD
 *
 ***********************************************************************************************************************************
 */
import I18n from './vendor/I18n.js';
import mm from 'moment-timezone';
//import 'jquery-datetimepicker'; //https://xdsoft.net/jqplugins/datetimepicker/
import jqv from 'jquery-validation';

import formUtilities from '../../public/js/admin/imports/forms.js'; //Utilities form
import utilitiesFunctions from '../../public/js/admin/imports/utilities.js';
import postSuccessFunctions from '../../public/js/admin/imports/postSuccessFunctions.js';
import editorFunctions from '../../public/js/admin/imports/texteditor.js';
import lapeUtilitiesFunctions from '../../public/js/admin/imports/lape_utilities.js'; //Utilities lape


//Moment.js
window.moment = mm;
moment.locale('it');
moment.tz('Europe/Rome');

//Datetimepicker
//jQuery.datetimepicker.setLocale('it');

window.I18n = I18n;
window.translator = new I18n;

//Jquery validation
window.jvalidate = jqv;

//Colorpicker: https://github.com/Simonwep/pickr
window.Pickr = require('@simonwep/pickr/dist/pickr.min');

//Jquery slugify
window.slugify = require('@sindresorhus/slugify');

window.bsMultiSelect = require('@dashboardcode/bsmultiselect/dist/js/BsMultiSelect.min');
import '@dashboardcode/bsmultiselect/dist/css/BsMultiSelect.min.css';

import select2 from 'select2';
import 'select2/dist/css/select2.css';
import '@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css';

//import { animateFill } from 'tippy.js';   // Viene importato in bootstrap.js
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/perspective-extreme.css';
import 'tippy.js/animations/scale-extreme.css';


import Rellax from "rellax";
window.Rellax = Rellax;

window.rowreorder = require('datatables.net-rowreorder');

window.ff = new formUtilities();
window.pf = new postSuccessFunctions();
window.te = new editorFunctions();
window.uf = new utilitiesFunctions();
window.lape = new lapeUtilitiesFunctions();
