class postSuccessFunctions {
    //Chiamata dinamica della funzione di successo sull'ajaxpost
    executeSuccess(successFunction, response) {
        //console.log(successFunction);
        if (successFunction !== '') {

            this[successFunction](response);
        }
    }

    /*
     *************************************************************************
     * FUNCTIONS
     *************************************************************************
     */

    //
    updateAdminMenuSection(response) {
        $("#caret_container" + response.sectionId).html(response.view);
    }

    //Users
    updateUsersList(response) {
        $("#users_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Templates
    updateTemplatesList(response) {
        $("#templates_container").html(response.view);
    }

    //Positions
    updatePositionsList(response) {
        $("#positions_container").html(response.view);
    }

    //Blocks
    updateBlocksList(response) {
        $("#blocks_container").html(response.view);
    }

    //Blocks - Carico record nella select di creazione/modifica blocco
    fillModelContent(response) {
        $("#records").html(response.view);
    }

    //Menus
    updateMenusList(response) {
        $("#menus_container").html(response.view);
    }

    //Menusections
    updateMenusectionsList(response) {
        $("#menusections_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Menuitems
    updateMenuitemsList(response) {
        $("#menuitems_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Pages
    updatePagesList(response) {
        $("#pages_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Categories
    updateCategoriesList(response) {
        $("#categories_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Tags
    updateTagsList(response) {
        $("#tags_container").html(response.view);
    }

    //Roles
    updateRolesList(response) {
        $("#roles_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Permissions
    updatePermissionsList(response) {
        $("#permissions_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    /*
     *************************************************************************
     * CONTACTS FORM
     *************************************************************************
     */
    updateContactsForm(response) {
        //console.log(response);
        $(".form-response")
            .removeClass("collapse")
            .html(response.responseText);
    }

    updateProfessionList(response) {
        $("#profession_id").html(response.view);
    }

    /*
     *************************************************************************
     * MAILLING LIST
     *************************************************************************
     */
    //Mailinglist
    updateSubscribersList(response) {
        $("#subscribers_container").html(response.view);
    }

    updateMailingGroupsList(response) {
        $("#mlgroups_container").html(response.view);
    }

    updateMailinglistList(response) {
        $("#mailinglists_container").html(response.view);
    }

    //Mailinglist
    updateSubscribersList(response) {
        $("#subscribers_container").html(response.view);
    }

    //Iscrizione mailing list
    updateNewsletterForm(response) {
        //console.log(response);
        $(".newsletter-response")
            .removeClass("collapse")
            .addClass("alert-" + response.class)
            .html(response.responseText);

        if (response.class == "success") {
            $("#" + response.formid)[0].reset();
        }
    }

    //Cancellazione da mailing list
    updateUnsubscribeForm(response) {
        //console.log(response);
        $(".form-response")
            .removeClass("collapse")
            .addClass("alert-" + response.class)
            .html(response.responseText);

        if (response.class == "success") {
            $("#" + response.formid)[0].reset();
        }
    }

    /*
     ******************************************************************************************************************************************************
     * MEDIA
     ******************************************************************************************************************************************************
     */

    //Mostro i file della cartella
    updateImagesGrid(response) {
        $("#" + response.container).html(response.view);
        $("#selected_files").val(response.selectedImages);
        loadDropzone("dropzone_images");
    }

    //Gallery - Carico record nella select di creazione/modifica gallery
    getRelatedRecords(response) {
        $("#record_id").html(response.view);
    }

    //Gallery - Ricarico elenco files dopo aggiunta da modale
    updateTableItemsContainer(response) {
        //console.log(response.images);
        $("#table_items_container").html(response.view);
    }

    /*
     ******************************************************************************************************************************************************
     * UPLOADER
     ******************************************************************************************************************************************************
     */
    /*
     updateUploadList(response) {
         console.log(response.urls);
         $('.' + response.divtoreload).html(response.view);
     }
     */

    updateCoverContainer(response) {
        $("." + response.divtoreload + " .img").html(response.replaceHtml);
        $("#" + response.inputId).val("");
    }

    /*
     ******************************************************************************************************************************************************
     * DATATABLE
     ******************************************************************************************************************************************************
     */

    datatableUpdateSort(response) {
        //console.log(response);
    }

    /*
     ******************************************************************************************************************************************************
     *
     *
     * LAPE
     *
     *
     ******************************************************************************************************************************************************
     */

    //Filtri applicazioni su pagina prodotti
    updateApplicationTypeList(response) {
        $("#applications-list").html(response.view);
        $("#products-container").html(response.view_products);
    }

    //Sub Filtri applicazioni su pagina prodotti
    updateApplicationList(response) {
        //$('#applications-list').html(response.view);
        $("#products-container").html(response.view);
    }

    //Filtro documentazione in base al tipo di isolamento
    updateDocumentationList(response) {
        console.log(response.insulationtypeId);
        $("#technical_documents_container").html('response.view');
    }

    /*
     ******************************************************************************************************************************************************
     * LAPE ADMIN
     ******************************************************************************************************************************************************
     */


    /*
     *********************************************************************
     *
     * COMPANIES / OFFICES
     *
     *********************************************************************
     */
    storeAndCloseCompany(response) {
        $('#company-msg-container').html(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            'type': 'POST',
            'uri': response.officeRoute,
            'data': {
                'companyId': response.companyId,
            },
            'onSuccess': 'showOfficesView',
            'params': '',
        }

        //console.log(postParams);
        uf.ajaxPost(postParams);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");


    }

    storeAndCloseOffice(response) {
        $('#office-msg-container').html(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            'type': 'POST',
            'uri': response.officeRoute,
            'data': {
                'companyId': response.companyId,
            },
            'onSuccess': 'showOfficesView',
            'params': '',
        }

        //console.log(postParams);
        uf.ajaxPost(postParams);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");


    }

    storeAndCloseReferent(response) {
        $('#referent-msg-container').html(response.msgView);

        //console.log(response);

        //Aggiorno la vista delle aziende
        var postParams = {
            'type': 'POST',
            'uri': response.referentRoute,
            'data': {
                'companyId': response.companyId,
                'officeId': response.officeId,
            },
            'onSuccess': 'showReferentsView',
            'params': '',
        }

        //console.log(postParams);
        uf.ajaxPost(postParams);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");


    }


    /*
     *********************************************************************
     *
     * FAMILIES / PRODUCTS / ARTICLES / DOCUMENTS
     *
     *********************************************************************
     */

    updateFamiliesList(response) {
        $("#families_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Ricarico lista allegati famiglia
    updateAttachmentList(response) {
        $("#documents_container").html(response.view);
    }

    updateProductsList(response) {
        $("#products_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateArticlesList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //Carico lista prodotti su cambio famiglia
    articlesUpdateProductsList(response) {
        //console.log(response.familyId);
        var productDisabled = response.familyId > 0 ? false : true;
        var container = response.container;

        $("#" + container).prop("disabled", productDisabled);

        $("#" + container).html(response.view);
        $("#table_container").html("");
    }

    //Mostro esito edit prodotto in modale
    showEditProductResult(response) {

        //console.clear();
        //console.log(response.fields);
        $("#table_container").html(response.listView);

        $('#' + response.modalId + ' .modal-body').html(response.view);
        $('#' + response.modalId + ' .modal-footer').html(response.footerView); //Eventale vista da includere nel footer
        uf.jqMultiselect('multiselect');
        uf.dataTable("table-paginate");
    }

    //Mostro esito edit articolo in modale
    showEditArticleResult(response) {

        //console.clear();
        //console.log(response);
        $("#table_container").html(response.listView);

        $('#' + response.modalId + ' .modal-body').html(response.view);
        $('#' + response.modalId + ' .modal-footer').html(response.footerView); //Eventale vista da includere nel footer
        uf.dataTable("table-paginate");
    }

    //Aggiorno elenco documenti
    updateDocumentsList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");

        //Tippyjs Tooltip
        tippy(".tippy", {
            allowHTML: true,
            arrow: true,
            theme: "light",
            maxWidth: 500
        });
    }


    //Aggiorno riga tabella documenti
    updateDocumentsRow(response) {
        $("#row_" + response.documentId).html(response.view);

        //Tippyjs Tooltip
        tippy(".tippy", {
            allowHTML: true,
            arrow: true,
            theme: "light",
            maxWidth: 500
        });
    }

    //aggiorno riga tabella prodotti
    productsUpdateProductRow(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        //var productDisabled = response.familyId > 0 ? false : true;

        $("#row_" + response.productId).html(response.view);
        //uf.dataTable("table-paginate");
    }


    //Carico lista articoli su cambio famiglia, eliminazione multipla
    articlesUpdateArticlesList(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        var productDisabled = response.familyId > 0 ? false : true;

        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    //aggiorno riga tabella articoli
    articlesUpdateArticleRow(response) {
        //console.log(response.familyId);
        //console.log(response.productId);
        //var productDisabled = response.familyId > 0 ? false : true;

        $("#row_" + response.articleId).html(response.view);
        //uf.dataTable("table-paginate");
    }


    updateApplicationtypesList(response) {
        $("#applicationtypes_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateApplicationsList(response) {
        $("#applications_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateCertificationsList(response) {
        $("#certifications_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatePropertygroupsList(response) {
        $("#propertygroups_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatedopgroupsList(response) {
        $("#dopgroups_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updatePropertiesList(response) {
        $("#properties_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateTechnicalsheetsList(response) {
        $("#table_content").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateStandardsList(response) {
        $("#standards_container").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateDocumentpropertiesList(response) {
        $("#table_content").html(response.view);
        uf.dataTable("table-paginate");
    }

    updateTechnicalPropertiesList(response) {
        $("#technicalsheets_list_container").html(response.view);
        $("#propertyFilter").val(null);
        $("#propertyFilter").hideseek({
            attribute: "data-search"
        });
    }

    //Mostro form modifica proprietà nella gestione delle schede tecniche
    updateContainerItems(response) {
        $("#containerItems").html(response.view);

        //Select2 per le select dentro la form di modifica delle proprietà delle st
        $(".select2-item").select2({
            theme: "bootstrap4"
        });
    }

    //Ricarico pagina edit dopo eliminazione document-property
    updateDocumentPropertiesContainer(response) {
        $("#documentproperties_list_container").html(response.view);

        //Select2 per le select dentro la form di modifica delle proprietà delle st
        //Select2 per la selezione di una proprietà della scheda tecnica
        $('.select2-properties').select2({
            theme: 'bootstrap4',
            templateResult: formatOption,
        }).on('select2:select', function (e) {

            var data = e.params.data;
            var rotta = $(this).attr('data-route');
            var productId = $(this).attr('data-product-id');

            if (data.id != '') {
                var postParams = {
                    'type': 'POST',
                    'uri': rotta,
                    'data': {
                        'propertyId': data.id,
                        'productId': productId,
                    },
                    'onSuccess': 'updateContainerItems',
                    'params': '',
                }

                //console.log(postParams);
                uf.ajaxPost(postParams);
            }
        });

        function formatOption(listItem) {

            var saved = $(listItem.element).data('saved');
            var bg = saved ? 'bg-warning' : '';

            if (!listItem.id) {
                return listItem.text;
            }

            var $item = $(
                '<div class="py-1 ' + bg + '">' + listItem.text + '</div>'
            );
            return $item;
        };
    }




    //Update sort in modale
    updateTechnicalsheetsModalList(response) {
        $(".modal-body").html(response.view);
        uf.dataTable("table-paginate-modal");
    }

    //Update sort in modale
    updateDocumentpropertiesModalList(response) {
        $(".modal-body").html(response.view);
        uf.dataTable("table-paginate-modal");
    }

    //Opend technicalsheet duplicate modal
    openDuplicateModal(response) {
        var modalId = response.modalId;

        $("#" + modalId + " .modal-body").html(response.view);
        $("#" + modalId).modal({
            backdrop: "static" //Chiudo la modal solo cliccando su close
        });

        $(".duplicate-item")
            .select2({
                theme: "bootstrap4"
            })
            .on("select2:select", function (e) {
                var data = e.params.data;

                var confirmParams = {
                    title: $(".duplicate-item").attr("data-confirm-title"),
                    message: $(".duplicate-item").attr("data-confirm-message"),
                    btnConfirm: $(".duplicate-item").attr("data-btn-confirm"),
                    btnDismiss: $(".duplicate-item").attr("data-btn-dismiss")
                };

                //Rotta di conferma sulla selezione del prodotto
                var confirmRoute = $(".duplicate-item").attr("data-route");

                //Id del prodotto da duplicare
                var parentId = $(".duplicate-item").attr("data-parent-id");

                var postParams = {
                    type: "POST",
                    uri: confirmRoute,
                    data: {
                        parentId: parentId,
                        productId: data.id, //Nuovo prodotto a cui associare la scheda
                        modalId: modalId
                    },
                    onSuccess: "closeAndUpdateDocumentpropertiesList",
                    params: ""
                };

                //console.log(postParams);

                uf.confirmBox(confirmParams, postParams);
            });
    }

    closeAndUpdateDocumentpropertiesList(response) {
        $("#archivelist").html(response.view);
        //uf.dataTable('table-paginate');

        //console.log(response.modelPlural);
        //console.log(response.modelSingular);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");
    }

    //Genero revisione
    /*
    updateArticlesList(response) {
        $("#table_container").html(response.view);
        uf.dataTable("table-paginate");
    }
    */


    //Chiudo modale motivazioni offerta
    closeAndUpdateOfferList(response) {
        //$("#offerlist").html(response.view);
        //uf.dataTable('table-paginate');

        //console.log(response.message);

        //console.log(response.modelPlural);
        //console.log(response.modelSingular);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");

        //Redirect dopo 1 secondo
        setTimeout(function () {
            window.location.href = response.redirectAfterClose;
        }, 100);


    }

    /*
     *********************************************************************
     *
     * OFFERS
     *
     *********************************************************************
     */
    //Filtro prodotti per applicazione
    filterProductsList(response) {
        console.log('io');
        console.log(response.results);
        $("#products_container").html(response.view);
    }


    //Usercompanies
    updateLinkContainer(response) {
        //console.log(response.productId);
        $("#linkPreview").addClass("d-flex");
        $("#linkPreview .alertcontent").html("revisione generata");
        $("#linkPreview").fadeIn("slow");
    }

    //Usercities - Update region
    updateUsercityContent(response) {
        //console.log(response.data);
        $("#btn-" + response.regionName + "-tab").html(response.buttonView);
        $("#usercity-" + response.regionName).html(response.view);
        uf.dataTable("table-paginate");
        uf.jqMultiselect("multiselect");
    }

    //Zonecities - Update region
    updateZonecityContent(response) {
        //console.log(response.data);
        $("#btn-" + response.regionName + "-tab").html(response.buttonView);
        $("#zonecity-" + response.regionName).html(response.view);
        uf.dataTable("table-paginate");
        uf.jqMultiselect("multiselect");
        $(".multiselect, select[multiple='multiple']").bsMultiSelect({
            useCssPatch: true, // default, can be ommitted
            cssPatch: {
                // choices - dropdown menu items
                choices: {},
                choice: "pl-2"
            },

            setSelected: function (option /*element*/, value /*true|false*/) {
                var zoneid = option.getAttribute("data-zone-id");
                var regionid = option.getAttribute("data-region-id");
                var provinceid = option.getAttribute("data-province-id");
                var cityid = option.getAttribute("value");
                var rotta = option.getAttribute("data-route");

                if (value) {
                    option.setAttribute("selected", "");
                } else {
                    option.removeAttribute("selected");
                }

                var postParams = {
                    type: "POST",
                    uri: rotta,
                    data: {
                        active: value,
                        regionid: regionid,
                        zoneid: zoneid,
                        provinceid: provinceid,
                        cityid: cityid
                    },
                    onSuccess: "updateZonecityCities",
                    params: ""
                };

                //console.log(postParams);
                uf.ajaxPost(postParams);

                option.selected = value;
            }
        });
    }

    //Zonecities - Update cities
    updateZonecityCities(response) {
        //console.log(response.checkedP);
        $("#btn-" + response.regionName + "-tab").html(response.view);
        $("#region_" + response.zoneid + "_" + response.regionid).html(
            response.view1
        );
        $("#province_" + response.zoneid + "_" + response.provinceid).html(
            response.view2
        );
        //uf.jqMultiselect('multiselect');

        $(".multiselect, select[multiple='multiple']").bsMultiSelect({
            useCssPatch: true, // default, can be ommitted
            cssPatch: {
                // choices - dropdown menu items
                choices: {},
                choice: "pl-2"
            },

            setSelected: function (option /*element*/, value /*true|false*/) {
                var zoneid = option.getAttribute("data-zone-id");
                var regionid = option.getAttribute("data-region-id");
                var provinceid = option.getAttribute("data-province-id");
                var cityid = option.getAttribute("value");
                var rotta = option.getAttribute("data-route");

                if (value) {
                    option.setAttribute("selected", "");
                } else {
                    option.removeAttribute("selected");
                }

                var postParams = {
                    type: "POST",
                    uri: rotta,
                    data: {
                        active: value,
                        regionid: regionid,
                        zoneid: zoneid,
                        provinceid: provinceid,
                        cityid: cityid
                    },
                    onSuccess: "updateZonecityCities",
                    params: ""
                };

                //console.log(postParams);
                uf.ajaxPost(postParams);

                option.selected = value;
            }
        });
    }



    //Offer - Update cart
    updateCart(response) {
        //console.log(response.data);
        if (response.reloadView == 'table') {
            $("#offerdetails").html(response.view);
        }
        else {
            $("#cart_container").html(response.view);
        }
        //Chiudo modale
        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");
    }

    //Offer - Aggiorno dettaglio e chiudo modale
    updateOfferDetail(response) {
        //console.log(response.fields);

        $("#offerdetails").html(response.view);

        //Chiudo modale
        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");

    }

    /*
     ******************************************************************************************************************************************************
     *
     * REPORTS
     *
     ******************************************************************************************************************************************************
     */
    updateReportOffersList(response) {
        $("#table_container").html(response.view);
        $("#offers_container").html(response.infoview);
        //uf.dataTable("table-paginate");
    }

    updateReportOrdersList(response) {
        $("#table_container").html(response.view);
        $("#orders_container").html(response.infoview);
        //uf.dataTable("table-paginate");
    }

    /*
     *********************************************************************
     *
     * TICKETS
     *
     *********************************************************************
     */

    //Chiude la modale per la risposta ad un ticket e aggiorna elenco risposte
     closeModalAnswer(response) {
        $("#tab-conversation").html(response.view);

        $("#" + response.modalId).modal("toggle");
        $(".modal-backdrop").remove();
        $(document.body).removeClass("modal-open");
        lape.loadSuggestionS2('.s2autocompleteTickets');

    }

    //Elimina risposta e aggiorna elenco risposte
    updateTicketAnswers(response) {
        $("#tab-conversation").html(response.view);
        lape.loadSuggestionS2('.s2autocompleteTickets');
    }

    /*
     ******************************************************************************************************************************************************
     *
     * LAPE BACKEND APP
     *
     ******************************************************************************************************************************************************
     */

    showOfficesView(response) {
        $("#offices_container").html(response.view);

    }

    showReferentsView(response) {
        $("#referents_container").html(response.view);
    }


    /*
     *********************************************************************
     *
     * UTILITIES
     *
     *********************************************************************
     */
    //Salvataggio aziende
    updateImportCompanies(response) {
        $("#import_companies_container").html(response.view);
        $("#oldcompanies_container").html(response.tableView);

        uf.dataTable("table-paginate");

    }

    //Salvataggio visite
    updateImportVisits(response) {
        $("#import_visits_container").html(response.view);
        $("#oldvisits_container").html(response.tableView);

        uf.dataTable("table-paginate");

    }

    /*
     ******************************************************************************************************************************************************
     *
     * TESTS
     *
     ******************************************************************************************************************************************************
     */

    //GENERA LISTINI
    pricelistsUpdateFamiliesList(response) {
        //console.log(response.showListino);
        $("#family").html(response.view);
        //console.log(response.showListino);
    }

    pricelistsUpdateProductsList(response) {
        //console.log(response.showListino);
        var productDisabled = response.familyId > 0 ? false : true;
        var zoneDisabled = response.productId > 0 ? false : true;

        $("#product").prop("disabled", productDisabled);
        $("#zone").prop("disabled", zoneDisabled);
        $("#zone option:first").attr("selected", "selected");
        $("#export-pricelist").prop("disabled", zoneDisabled);
        //$('#pricelists').html('');

        $("#product").html(response.view);
    }

    pricelistsUpdateZoneList(response) {
        //console.log(response.productId);
        //console.log(response.zones);

        var zoneDisabled = $.isEmptyObject(response.zones) ? true : false;

        $("#zone").prop("disabled", zoneDisabled);
        $("#export-pricelist").prop("disabled", zoneDisabled);
        $("#zone").html(response.view);
        //@ts-ignore$('#pricelists').html('');
    }

    pricelistsEnablePriceListButton(response) {
        $("#generate-pricelist").prop("disabled", response.buttonDisabled);
    }

    pricelistsUpdatePriceList(response) {
        //console.log(response.productId);
        $("#pricelists").html(response.view);

        $("#pricelist-table.table-paginate").DataTable({
            //Per far funzionare il popover quando si cambia pagina
            drawCallback: function () {
                // uf.showPopover();
            }
        });
    }

    //IMPORTA ID MAGO
    idmagosUpdateFamiliesList(response) {
        //console.log(response.showListino);
        $("#family").html(response.view);
        //console.log(response.showListino);
    }

    //Salvataggio idmago importati da excel
    updateImportMagos(response) {

        //console.log(response.data);
        $("#saved_container").html(response.view);
    }


    idmagosUpdateProductsList(response) {
        //console.log(response.showListino);

        var productDisabled = response.familyId > 0 ? false : true;
        var zoneDisabled = response.productId > 0 ? false : true;

        $("#product").prop("disabled", productDisabled);
        $("#zone").prop("disabled", zoneDisabled);
        $("#zone option:first").attr("selected", "selected");
        $("#export-pricelist").prop("disabled", zoneDisabled);
        //$('#pricelists').html('');

        $("#product").html(response.view);
    }
    /*
        idmagosUpdateZoneList(response) {
            //console.log(response.productId);
            //console.log(response.zones);

            var zoneDisabled = $.isEmptyObject(response.zones) ? true : false;

            $("#zone").prop("disabled", zoneDisabled);
            $("#export-pricelist").prop("disabled", zoneDisabled);
            $("#zone").html(response.view);
            //@ts-ignore$('#pricelists').html('');
        }

        idmagosEnablePriceListButton(response) {
            $("#generate-idmagos").prop("disabled", response.buttonDisabled);
        }

        idmagosUpdatePriceList(response) {
            //console.log(response.productId);
            $("#pricelists").html(response.view);

            $("#pricelist-table.table-paginate").DataTable({
                //Per far funzionare il popover quando si cambia pagina
                drawCallback: function () {
                    // uf.showPopover();
                }
            });
        }
    */
}



export default postSuccessFunctions; // ES6
