class lapeUtilitiesFunctions {


    test() {

        alert('lape utilities: ci sono');
    }


    /*
    ******************************************************************************************************************************************************
    *
    * Ajax Post
    *
    ******************************************************************************************************************************************************
    */
    /*
    Parameters
    uf = utilitiesFunctions
    pf = postSuccessFunctions
    postParams = Parametri per la chiamata ajax
    ac = (opz) autocompleteFunctions
    te = (opz) textEditorFunctions
    */

    ajaxPostLape(postParams) {

        var showLoading = true;

        if (showLoading) {
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        }

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            type: postParams.type,
            url: postParams.uri,
            data: postParams.data,
            success: function (response) {

                //Controllo se devo aprire una modale o fare chiamata ajax standard
                if (postParams.ismodal) {

                    var modalParams = {
                        'modalId': response.modalId,
                        'modalTitle': response.modalTitle,
                        'params': postParams.params,
                    }

                    //Che tipo di modale devo aprire
                    if (postParams.modalTicket) {
                        lape.showModalTicket(response, modalParams);
                    }
                    else {
                        uf.showModal(response, modalParams);
                    }

                } else {
                    //Chiamo dinamicamente la funzione di successo

                    pf.executeSuccess(postParams.onSuccess, response);
                };

                if (showLoading) {
                    uf.loaderStop();
                };

            },
            error: function (e, textStatus, errorThrown) {
                //console.log('AJAX error: ' + textStatus + ' : ' + errorThrown);
                //console.log('Errore...\n' + JSON.stringify(e));

                var err = JSON.parse(e.responseText);
                console.log(err);

                uf.loaderStop();

                //Chiudo il loader in caso di errore
                uf.loader('sk-cube-grid', '<h5>Attenzione, si è verificato un errore nella richiesta...</h5>' + err.message + '<br>in ' + err.file + ' riga ' + err.line);
                setTimeout(function () {
                    uf.loaderStop();
                }, 5000);
            }
        });
    }

    /*
    |--------------------------------------------------------------------------
    |
    | MODALI
    |
    |--------------------------------------------------------------------------
    */

    //Chiamo la funzione per recuperare il contenuto da inserire nella modale
    callModalLape(params) {

        var postParams = {
            'type': 'POST',
            'uri': params.rotta,
            'data': params.data,
            'onSuccess': params.onSuccess,
            'params': params.params,
            'ismodal': true,
            'modalTicket': params.params.modalTicket,

        };

        //console.log(postParams);

        lape.ajaxPostLape(postParams);

    }


    /*
    ******************************************************************************************************************************************************
    *
    * Documents TO DO WIP
    *
    ******************************************************************************************************************************************************
    */

    /*
         dataTableDocuments(className, uf = null, pf = null) {

            var rows = typeof ($('.' + className).attr('data-pageLength')) !== 'undefined' ? $('.' + className).attr('data-pageLength') : 10;
            uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
            $('.' + className).DataTable({

                'order': [
                    //Numero della colonna dell'ordinamento di default e direzione
                    $('.' + className).attr('data-orderBy'),
                    $('.' + className).attr('data-orderDirection')
                    //3, 'desc'
                ],
                scrollX: $('.' + className).attr('data-scrollX'),
                columnDefs: [

                    //Le colonne con questa classe non sono ordinabili
                    {
                        targets: 'no-sort',
                        orderable: false
                    },

                    //L'ordinamento per data non funziona al 100%. Usata la tecnica del campo nascosto con formato data YMd dove serve
                    //Le colonne con questa classe vengono formattate in dd/mm/yyyy
                    {
                        targets: 'daydate',
                        render: function (data) {
                            return moment.utc(data).format('DD/MM/YYYY');
                        }
                    },

                    //Le colonne con questa classe vengono formattate in dd-MM-YYYY H:i
                    {
                        targets: 'datetime',
                        render: function (data) {
                            return moment.utc(data).format('DD/MM/YYYY HH:mm');
                        }
                    },

                    //Le colonne con questa classe vengono nascoste
                    {
                        targets: 'hide',
                        visible: false,
                        searchable: true,
                    },
                ],
                orderClasses: false,
                pageLength: rows,
                //deferRender: true,
                //deferLoading: 57,
                processing: true,
                //serverSide: true, <-- Se attivato non funziona paginazione!
                /*
                serverMethod: 'POST',
                * /
                ajax: {
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    type: 'POST',
                    url: $('.' + className).attr('data-content-route'),
                    dataSrc: function (json) {
                        //Make your callback here.
                        console.log(json);
                        uf.loaderStop();
                        return json.data;
                    }
                },
                columns: [
                    { data: 'document' },
                    { data: 'type' },
                    { data: 'path' },
                    { data: 'version' },
                    { data: 'year' },
                    { data: 'updated_at' },
                    { data: 'families' },
                    { data: 'products' },
                    { data: 'visible' },
                    { data: 'selected' },
                    { data: 'preview' },
                ],

                //info: false,
                language: {
                    url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
                },

            });

        };
        */

    /*
     ******************************************************************************************************************************************************
     *
     * Select 2
     *
     ******************************************************************************************************************************************************
     */

    loadSuggestionS2(element) {

        var type = $(element).attr('data-id');

        //console.log($(element).attr("data-route"));

        $(element)
            .select2({
                minimumInputLength: 3,
                allowClear: true,
                placeholder: $(element).attr("data-placeholder"),
                theme: 'bootstrap4',

                ajax: {
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    },
                    url: $(element).attr("data-route"),
                    type: "POST",
                    dataType: "json",
                    data: function (params) {
                        var query = {
                            search: params.term,
                        }

                        // Query parameters will be ?search=[term]&type=public
                        return query;
                    },
                    processResults: function (data) {
                        var obj = {};

                        return {
                            results: $.map(data, function (item) {

                                //console.log(item);

                                switch (type) {
                                    case 'search-city':
                                        obj = {
                                            text: item.postal_code + ' ' + item.name + ' [' + item.code + ']',
                                            id: item.id,
                                            type: type,
                                        }
                                        break;

                                    case 'search-user':
                                        obj = {
                                            text: item.id + '] ' + item.surname + ' ' + item.name,
                                            id: item.id,
                                            type: type,
                                        }
                                        break;

                                }

                                return obj;
                            })
                        };
                    }
                },
            })

            .on('select2:select', function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data.type;

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val(cityId);
                        break;

                    case 'search-user':
                        /*
                        var userId = data.id;
                        $('#userid').val(userId);
                        */
                        break;

                }
            })
            .on('select2:clear', function (e) {
                var data = e.params.data; //Dati del record

                var type = e.params.data[0].type;
                //console.log(type);

                switch (type) {
                    case 'search-city':

                        var cityId = data.id;
                        $('#cityid').val('');

                        break;

                    case 'search-user':

                        var userId = data.id;
                        $('#userid').val('');

                        break;

                }
            })
    }


    /*
     ******************************************************************************************************************************************************
     *
     * Tickets
     *
     ******************************************************************************************************************************************************
     */

    //Mostro modale e aggiorno stato ticket
    showModalTicket(response, params) {

        //console.log(response);
        //console.log(params);

        var modalId = params.modalId;

        $('#' + modalId + ' .modal-body').html(response.view);
        $('#' + modalId + ' .modal-footer').html(response.footerView); //Eventale vista da includere nel footer
        //$('#' + modalId + ' .' + params.containerClass).html(response.view);
        $('#' + modalId).modal({
            backdrop: 'static' //Chiudo la modal solo cliccando su close
        });

        /*
        ***********************************************************
        *
        * AGGIORNO STATO TICKET
        *
        * **********************************************************
        */
        $("#ticketrow_" + response.ticketid).html(response.rowview);

        /*
        ***********************************************************
        *
        * PARAMETRI OPZIONALI PER CHIAMARE DEI PLUGIN
        *
        * **********************************************************
        */

        //Tinymce:  Nome della classe a cui applicare il tinymce
        if (params.params.editorId !== '') {
            te.removeAll();
            //te.removeEditor(params.params.editorId);
            te.loadTinyMCEEditor();
        }

        //Datepicker: Nome della classe a cui applicare il datepicker
        if (params.params.datepickerClass !== '') {
            uf.myDatePicker(params.params.datepickerClass);
        }

        //Datatble: Nome della classe della tabella a cui applicare il datatable
        if (params.params.datatableClass !== '') {
            uf.dataTable(params.params.datatableClass);
        }

        //Select2: Nome della classe a cui applicare la select2
        if (params.params.select2Class !== '') {
            uf.loadCitiesS2(params.params.select2Class);
        }

        //Multiselect: Nome della classe a cui applicare il multiselect
        if (params.params.multiselectClass !== '') {
            uf.jqMultiselect(params.params.multiselectClass);
        }
        //S2 per la ricerca di un prodotto
        if (params.params.s2SearchProducts !== '') {
            uf.s2SearchProducts('.s2autocompleteProduct');
        }


    }


    /*
     ******************************************************************************************************************************************************
     *
     * Datatable rubrica potenziata
     *
     ******************************************************************************************************************************************************
     */


    dataTableRubricaAjax(className) {

        var rows = typeof ($('.' + className).attr('data-pageLength')) !== 'undefined' ? $('.' + className).attr('data-pageLength') : 10;
        uf.loader('sk-bounce', '<h4>Caricamento contenuti. Attendere.</h4>');
        $('.' + className).DataTable({

            'order': [
                //Numero della colonna dell'ordinamento di default e direzione
                $('.' + className).attr('data-orderBy'),
                $('.' + className).attr('data-orderDirection')
                //3, 'desc'
            ],
            scrollX: $('.' + className).attr('data-scrollX'),
            columnDefs: [

                //Le colonne con questa classe non sono ordinabili
                {
                    targets: 'no-sort',
                    orderable: false
                },

                //L'ordinamento per data non funziona al 100%. Usata la tecnica del campo nascosto con formato data YMd dove serve
                //Le colonne con questa classe vengono formattate in dd/mm/yyyy
                {
                    targets: 'daydate',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY');
                    }
                },

                //Le colonne con questa classe vengono formattate in dd-MM-YYYY H:i
                {
                    targets: 'datetime',
                    render: function (data) {
                        return moment.utc(data).format('DD/MM/YYYY HH:mm');
                    }
                },

                //Le colonne con questa classe vengono nascoste
                {
                    targets: 'hide',
                    visible: false,
                    searchable: true,
                },
            ],
            orderClasses: false,
            pageLength: rows,
            //deferRender: true,
            //deferLoading: 57,
            processing: true,
            //serverSide: true, <-- Se attivato non funziona paginazione!
            /*
            serverMethod: 'POST',
            */
            ajax: {
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                type: 'POST',
                url: $('.' + className).attr('data-content-route'),
                dataSrc: function (json) {
                    //Make your callback here.
                    console.log(json);
                    uf.loaderStop();
                    return json.data;
                }
            },
            columns: [
                { data: 'channels' },
                { data: 'business_name' },
                { data: 'vat' },
                { data: 'province' },
                { data: 'classe_fatturato' },
                { data: 'classe_potenziale' },
                { data: 'visit_number' },
                { data: 'actions' },
            ],

            //info: false,
            language: {
                url: '//cdn.datatables.net/plug-ins/1.10.19/i18n/Italian.json'
            },

        });

    };



    //Calcolo n° pacchi
    //Parameters
    //Lunghezza articolo (in mm)
    //Larghezza articolo (in mm)
    //N° pezzi per pacco
    ///Qta ordinata

    getPackageNumber(lenght, width, itemPerPackage, qta) {

        //mq = Lunghezza * largezza in mm / 1000 (per avere i metri)

        //console.log(lenght + '-' + width + '-' + itemPerPackage + '-' + qta);
        if (itemPerPackage != '') {
            var mq = (parseFloat(lenght) / 1000 * parseFloat(width) / 1000).toFixed(0);

            var totalMq = mq * itemPerPackage;

            var number = (parseFloat(qta) / parseFloat(totalMq)).toFixed(2);
        }
        else {
            var number = 'manca valore pezzi per pacco';

        }
        //Mq per

        return number;

    }



    //Calcola la congruenza di un prezzo in base agli sconti applicati
    //basePrice: prezzo pieno dell'articolo
    //discount1: int, valore dello sconto
    ////discount2: int, valore dello sconto
    ////discount3: int. Se viene passato calcola congruenza prezzo
    discountCalculate(basePrice, discount1 = 0, discount2 = 0, discount3 = 0) {


        var congruous = true;
        var discountedPrice = basePrice;

        //console.log(lenght + '-' + width + '-' + itemPerPackage + '-' + qta);
        if (discount1 > 0) {
            var discountedPrice = parseFloat(discountedPrice) * (100 - discount1) / 1000;
        }

        if (discount2 > 0) {
            var discountedPrice = parseFloat(discountedPrice) * (100 - discount2) / 1000;
        }

        if (discount3 > 0) {
            var discountedPrice = parseFloat(discountedPrice) * (100 - discount3) / 1000;

            //Controllo congruenza
            congruous = parseFloat(discountedPrice) < parseFloat(basePrice) ? false : true;
        }

        var values = {
            'discountedPrice': discountedPrice.toFixed(2),
            'congruous': congruous,
        };


        return values;

    }
}

export default lapeUtilitiesFunctions // ES6
