/*
|=================================================================================================================================
|
| FUNZIONI UTILI PER LE FORMS
|
|=================================================================================================================================
*/


class formUtilities {
    //Funzione che abilita campi compilabili, li svuota e aggiunge dei bordi
    //Passo array di classi, per ognuna delle qualli scorro i campi ad essa associati e li abilito
    //Params:
    //bool enable: se true, abilito, altrimenti disabilito
    //array classi: array di classi da controllare
    //Se clearValue = true, pulisco il valore del campo
    enableDisabelFields(enable, classi) {

        //console.log(enable);
        $.each(classi, function (index, value, clearValue = false) {
            $('.' + value).each(function (item) {
                if (enable == 'true') {
                    $(this).prop('disabled', false);
                    $(this).addClass('border border-success');
                    if (clearValue) {
                        $(this).val('');
                    }
                } else {
                    $(this).prop('disabled', true);
                    $(this).removeClass('border border-success');
                    if (clearValue) {
                        $(this).val('');
                    }
                }
            });
        });
    }


    //Controllo che il campo sia numerico
    checkIsNumeric(fieldId) {

        var success = $.isNumeric($('#' + fieldId).val());

        if (!success) {
            setTimeout(function () {
                $('#' + fieldId).focus();
            }, 100);
            $('#' + fieldId).val('').attr('placeholder', 'Inserire valore numerico');
            return true;
        }
        return false;
    }

    //Valida una form
    validateForm(formId, cssRules, rules, messages) {

        var form = $('#' + formId);
        var hasTab = $('#' + formId).attr('data-hastab');


        console.log(form);

        $.validator.addMethod('valueNotEquals', function (value, element, arg) {
            return arg !== value;
        }, 'Value must not equal arg.');

        $.validator.addClassRules(cssRules);

        form.validate({
            debug: true,
            ignore: '.ignore', //Durante la validazione ignora i campi con classe "ignore"
            errorElement: 'span',
            errorClass: 'error',
            focusInvalid: true,
            onfocusout: false,
            rules: rules,
            messages: messages,
            /*
            errorPlacement: function () {},
            */
            /*
            submitHandler: function() {
                alert('Successfully saved!');
            },
            */
            /*
            errorPlacement: function(error, element) {
                error.appendTo("#errorbox-container");
              },
              */


            invalidHandler: function (form, validator) {
                setTimeout(function () {
                    var errors = validator.numberOfInvalids();
                    var firstError = validator.errorList[0].element.id;

                    //Pulisco eventuali errori precedenti
                    $("#errorbox-container").html('');

                    //Se sono in una modale, scrolltop si deve riferire al div della modale che carica il contenuto
                    var modalBody = $('.modal-body');
                    //console.log(modalBody.length);

                    if (errors) {

                        //console.log(validator.errorList);

                        //validator.errorList[0].element.focus();
                        //Check offset a seconda che siamo in una modale o in una finestra normale
                        var topPoint = modalBody.length > 0 ? modalBody : $('input[id="' + firstError + '"]');

                        $('html,body, .modal-body').animate({
                            scrollTop: topPoint.offset().top - 100
                            //scrollTop: $('input[id="' + firstError + '"]').offset().top - 100
                        }, 500);
                        $('input[id="' + firstError + '"]').focus();

                        $('#errorbox').addClass('alert alert-danger');

                        var errorList = validator.errorList

                        $.each(errorList, function (key, value) {
                            console.log(value.message);
                            var inputName = value.element.attributes.name.value;
                            console.log(inputName);
                            $("#errorbox-container").append('*** ' + inputName + ' ' + value.message + '<br>');

                        });


                        if (hasTab == 'true') {
                            $('.nav-tabs a small.required').remove();
                            var validatePane = $('.tab-content.tab-validate .tab-pane:has(input.error)').each(function () {
                                var id = $(this).attr('id');
                                $('.nav-tabs').find('a[href^="#' + id + '"]').append(' <small class="required" style="color:#f00">***</small>');


                            });
                        }

                        return false;

                    }
                    else {
                        $('#errorbox').removeClass('alert alert-danger');
                        $("#errorbox-container").html('');
                    }
                });
            },
        });



        /*
        //Regole per hiddenRecaptcha
        hiddenRecaptcha: {
            required: function() {
                if (grecaptcha.getResponse() == '') {
                    return true;
                } else {
                    return false;
                }
            }
        }
        */

        return form.valid();

    }



    //Invio form
    sendForm(formId) {

        //console.clear();

        var rotta = $('#' + formId).attr('data-route');
        var successFunction = $('#' + formId).attr('data-success');
        var dataRoute = $('#' + formId).attr('data-route'); //Spedisco la form in ajax o no. Default false

        //console.log(dataRoute);
        /*
        var pageSize = $('#' + formId).attr('data-pagesize');
        var pages = $('#' + formId).attr('data-pages');
        var currentPage = $('#' + formId).attr('data-currentpage');
        */
        var isAjax = (dataRoute == '' || typeof (dataRoute) == 'undefined') ? false : true;

        //console.log($('#' + formId).serializeArray());
        //console.log(isAjax);


        if (isAjax == true) {

            var postParams = {
                'type': 'POST',
                'uri': dataRoute,
                'data': {
                    'formValues': $('#' + formId).serializeArray(),
                },
                'onSuccess': successFunction, //'submittedForm',
                'params': {
                    'formid': formId,
                }
            }

            //console.log(postParams);

            uf.ajaxPost(postParams);
        } else {
            $('#' + formId)[0].submit();
        }
    }

}


export default formUtilities; //ES6
